export const sortingtypeConstant = [
  {
    id: "idAscending",
    name: "Sortby: ID (Ascending)",
  },
  {
    id: "idDescending",
    name: "Sortby: ID (Descending)",
  },
  {
    id: "emailAscending",
    name: "Sortby: Email (A-Z)",
  },
  {
    id: "emailDescending",
    name: "Sortby: Email (Descending)",
  },
  {
    id: "deviceAscending",
    name: "Sortby: Device Count (Ascending)",
  },
  {
    id: "deviceDescending",
    name: "Sortby: Device Count (Descending)",
  },
  { id: "nameEngAscending", name: "Sort by: English Name (Ascending)" },
  {
    id: "nameEngDescending",
    name: "Sort by: English Name (Descending)",
  },
  {
    id: "courseNameAscending",
    name: "Sort by: Course Name (Ascending)",
  },
  {
    id: "courseNameDescending",
    name: "Sort by: Course Name (Descending)",
  },
  {
    id: "nameAscending",
    name: "Sort by: Thai Name (Ascending)",
  },
  {
    id: "nameDescending",
    name: "Sort by: Thai Name (Descending)",
  },
  {
    id: "taggingAscending",
    name: "Sort by: Tagging Name (Ascending)",
  },
  {
    id: "taggingDescending",
    name: "Sort by: Tagging Name (Descending)",
  },
  { id: "episodeNumberAscending", name: "Sort by: Episode Number (Ascending)" },
  {
    id: "episodeNumberDescending",
    name: "Sort by: Episode Number (Descending)",
  },
  { id: "sequenceAscending", name: "Sort by: Sequence (Ascending)" },
  { id: "sequenceDescending", name: "Sort by: Sequence (Descending)" },
  { id: "durationAscending", name: "Sort by: Duration (Ascending)" },
  { id: "durationDescending", name: "Sort by: Duration (Descending)" },
  { id: "DateAscending", name: "Sort by: Create Date (Ascending)" },
  { id: "DateDescending", name: "Sort by: Create Date (Descending)" },
  {
    id: "updateDateAscending",
    name: "Sortby: Last Activity (Ascending)",
  },
  {
    id: "updateDateDescending",
    name: "Sortby: Last Activity (Descending)",
  },
];

export const userSortingConstant = [
  "idAscending",
  "idDescending",
  "emailAscending",
  "emailDescending",
  "taggingAscending",
  "taggingDescending",
  "deviceAscending",
  "deviceDescending",
  "DateAscending",
  "DateDescending",
  "updateDateAscending",
  "updateDateDescending",
];

export const instructorSortingConstant = [
  "idAscending",
  "idDescending",
  "nameEngAscending",
  "nameEngDescending",
  "nameAscending",
  "nameDescending",
  "DateAscending",
  "DateDescending",
  "updateDateAscending",
  "updateDateDescending",
];

export const courseSortingConstant = [
  "idAscending",
  "idDescending",
  "nameEngAscending",
  "nameEngDescending",
  "taggingAscending",
  "taggingDescending",
  "DateAscending",
  "DateDescending",
  "updateDateAscending",
  "updateDateDescending",
  "sequenceAscending",
  "sequenceDescending",
];

export const episodeSortingConstant = [
  "idAscending",
  "idDescending",
  "courseNameAscending",
  "courseNameDescending",
  "episodeNumberAscending",
  "episodeNumberDescending",
  "DateAscending",
  "DateDescending",
  "updateDateAscending",
  "updateDateDescending",
];

export const accessgroupSortingConstant = [
  "idAscending",
  "idDescending",
  "durationAscending",
  "durationDescending",
  "taggingAscending",
  "taggingDescending",
  "DateAscending",
  "DateDescending",
  "updateDateAscending",
  "updateDateDescending",
];

export const subscriptionRecordSortingConstant = [
  "idAscending",
  "idDescending",
  "emailAscending",
  "emailDescending",
  "DateAscending",
  "DateDescending",
];
