import { Select } from "antd";
import { sortingtypeConstant } from "../../constants/sorting.constants";

const { Option } = Select;

export default function SortingTypeOption({
  types,
  valueType,
  changeFunction,
}) {
  function checkOption(name) {
    return types.indexOf(name) !== -1;
  }

  return (
    <>
      <Select
        defaultValue={"idAscending"}
        className={"w-100percent"}
        value={valueType}
        onChange={(value) => {
          changeFunction(value);
        }}
      >
        {sortingtypeConstant.map((eachType) => {
          return checkOption(eachType.id) ? (
            <Option value={eachType.id} key={eachType.id}>
              {eachType.name}
            </Option>
          ) : (
            <></>
          );
        })}
      </Select>
    </>
  );
}
